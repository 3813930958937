<template>
  <v-dialog
    persistent
    v-model="dialog"
    width="auto"
    content-class="elevation-4"
  >
    <v-form>
      <v-card width="370px" class="pa-3 rounded-lg">
        <!-- class="ma-1" dentro do v-card é a "caixa fantasma"
         também presente no DataTable!  -->
        <v-card-title class="text-h7 pa-1 ma-2">
          Confirmar a exclusão?
        </v-card-title>
        <v-card-text style="height: 50px">
          <v-row>
            <v-col class="pa-1">
              <v-alert border="left" colored-border color="red" dense text>
                <strong>Atenção!</strong> Essa é uma ação
                <strong class="strong">irreversível</strong>.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-0 ma-2">
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="dialog = false"
            :to="{ name: 'Customers' }"
            dense
          >
            Cancelar
          </v-btn>
          <v-btn
            color="#fc6464"
            dark
            @click="deleteCustomers"
            filled
            style="padding: 16px"
            dense
          >
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<style lang="stylus">
.text-h7 {
  color: #5D5D5D;
  font-weight: 550 !important;
  font-size: 16px !important;
}
.strong {
  color: red;
}
</style>

<script>
import CustomersService from "./service";
export default {
  name: "DeleteCustomer",
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    async deleteCustomers() {
      await CustomersService.deleteCustomers(this.$route.params.id);
      this.$router.push({ name: "Customers" });
      this.$emit("created", {
        customers: { name: "" },
        typeForm: "DeleteCustomer",
      });
    },
  },
};
</script>
